// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBPHbZUo7GKKUmVedFrd83dyFPk-7Ldhjs',
  authDomain: 'house-marketplace-app-e061a.firebaseapp.com',
  projectId: 'house-marketplace-app-e061a',
  storageBucket: 'house-marketplace-app-e061a.appspot.com',
  messagingSenderId: '189662017084',
  appId: '1:189662017084:web:b7257e90da118cd17bb539',
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const db = getFirestore();
